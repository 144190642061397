<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Linha" : "Cadastrar Linha" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card :class="`mb-${linha.id_cad_linhas == 0 ? '15' : '5'}`">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Linhas</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="6">
              <span class="primary--text">Descrição *</span>
              <v-text-field
                v-model="dados.descricao"
                :rules="fieldRules"
                required
                placeholder="Descrição"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Prefixo da Linha</span>
              <v-text-field
                v-model="dados.sigma"
                placeholder="Informe o Prefixo da Linha"
                class="mt-n1"
                :rules="fieldRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Prefixo SGP/SIGMA *</span>
              <v-text-field
                v-model="dados.prefixo"
                placeholder="Informe o Prefixo SGP/SIGMA"
                class="mt-n1"
                :rules="fieldRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Tipo de Serviço</span>
              <v-select
                :items="tipos"
                placeholder="Informe o tipo de serviço"
                class="mt-1 custom-solo-selection"
                solo
                v-model="dados.id_tipo_servico"
                item-text="tipo_servico"
                item-value="id"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Orgão Regulador</span>
              <v-autocomplete
                v-model="dados.id_orgao_regulador"
                placeholder="Informe o orgão regulador"
                class="mt-1 custom-solo-selection"
                solo
                :rules="fieldRules"
                item-text="descricao"
                item-value="id"
                required
                :items="orgaos"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Modalidade *</span>
              <v-text-field
                v-model="dados.modalidade"
                placeholder="Informe a modalidade"
                class="mt-n1"
                :rules="fieldRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Categoria</span>
              <v-text-field
                v-model="dados.categoria"
                placeholder="Informe a categoria"
                class="mt-n1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-footer
        color="primary"
        class="rounded-t footer-form"
        absolute
        v-if="linha.id_cad_linhas == 0"
      >
        <div class="flex-grow-1"></div>
        <v-btn dark depressed text :disabled="!valid" @click="validate"
          >Adicionar Linha</v-btn
        >
      </v-footer>

      <v-card class="mb-5" v-if="linha.id_cad_linhas > 0 || id">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Adicionar Linha</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="6">
              <span class="primary--text">Localidade</span>
              <v-autocomplete
                :items="localidades"
                v-model="linha.id_localidade"
                item-text="nome"
                item-value="id"
                placeholder="Informe a localidade"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Distância Km</span>
              <v-text-field
                v-model="linha.distancia_km"
                required
                v-mask-number
                placeholder="Informe a distância"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Tempo</span>
              <v-text-field
                v-model="linha.tempo"
                required
                v-mask="'00:00'"
                placeholder="Informe o tempo"
                class="mt-n1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-footer color="primary">
          <div class="flex-grow-1"></div>
          <v-btn dark depressed text @click="salvarRota">Salvar</v-btn>
        </v-footer>
      </v-card>

      <v-card v-if="linha.id_cad_linhas > 0 || id" class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Linhas Adicionadas</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" lg="12">
              <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-0"
                style="width: 100%"
              >
                <template v-slot:item.action="{ item }">
                  <v-icon
                    small
                    @click="editRoute(item)"
                    color="primary"
                    class="mr-2"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small @click="deleteRoute(item.id)" color="error"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-footer
        v-if="linha.id_cad_linhas > 0 || id"
        color="primary"
        class="rounded-t footer-form"
        absolute
      >
        <div class="flex-grow-1"></div>
        <v-btn dark depressed text @click="salvar">Confirmar</v-btn>
      </v-footer>
    </v-form>

    <v-dialog v-model="showModal" width="400">
      <v-card>
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações de Endereço</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row dense>
            <v-col cols="12" sm="12">
              <span class="primary--text">Localidade</span>
              <v-autocomplete
                :items="localidades"
                v-model="linha.id_localidade"
                item-text="nome"
                item-value="id"
                placeholder="Informe a localidade"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12">
              <span class="primary--text">Distância Km</span>
              <v-text-field
                v-model="linha.distancia_km"
                required
                v-mask-number
                placeholder="Informe a distância"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <span class="primary--text">Tempo</span>
              <v-text-field
                v-model="linha.tempo"
                required
                v-mask="'00:00'"
                placeholder="Informe o tempo"
                class="mt-n1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="primary">
          <v-spacer />
          <v-btn dark text @click="salvarRota">SALVAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      menu: false,
      showModal: false,
      loading: false,
      orgaos: [],
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        descricao: "",
        prefixo: "",
        id_tipo_servico: 0,
        id_orgao_regulador: "",
        modalidade: "",
        categoria: "",
        sigma: null,
      },
      linha: {
        id_localidade: 0,
        tempo: "",
        distancia_km: "",
        id_cad_linhas: 0, //Chave primaria da primeira tabela
      },
      tipos: [],
      localidades: [],
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      headers: [
        { text: "Localidade", value: "localidade", sortable: false },
        { text: "Distância", value: "distancia_km", sortable: false },
        { text: "Tempo", value: "tempo", sortable: false },
        { text: "Ações", align: "right", value: "action", sortable: false },
      ],
      items: [],
    };
  },
  created() {
    this.loader("tiposservico");
    this.loader("localidades");
    this.loader("orgaoregulador");
    if (this.id) {
      this.linha.id_cad_linhas = this.id;
      this.loaderRotas();
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/linhas/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    clearFields() {
      this.linha = {
        id_localidade: 0,
        tempo: "",
        distancia_km: "",
        id_cad_linhas: 0,
      };
    },
    loader(table) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/` + table)
        .then((r) => {
          table == "tiposservico"
            ? (this.tipos = r.data)
            : table == "localidades"
            ? (this.localidades = r.data)
            : (this.orgaos = r.data);
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.dados.descricao = this.dados.descricao
        ? this.dados.descricao.toUpperCase()
        : this.dados.descricao;
      this.dados.modalidade = this.dados.modalidade
        ? this.dados.modalidade.toUpperCase()
        : this.dados.modalidade;
      this.dados.categoria = this.dados.categoria
        ? this.dados.categoria.toUpperCase()
        : this.dados.categoria;
      this.$store.dispatch("activeLoader", true);
      if (this.id) {
        this.$http
          .post(`/linhas/update/${this.id}`, this.dados) // link da API
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros-operacionais/linhas/gerenciar"); // Link da rota
              }, 500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/linhas/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.linha.id_cad_linhas = r.data.key;
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              this.id = this.linha.id_cad_linhas;
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
    salvarRota() {
      if (this.linha.id) {
        delete this.linha.localidade;
        this.linha.id_cad_linhas = this.id;
        this.$http
          .post(`/rotas/update/${this.linha.id}`, this.linha)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.key = r.data.key;
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              this.loaderRotas();
              this.clearFields();
              this.showModal = false;
            } else {
              this.message.text = r.data.error;
              this.message.snacshow = true;
              this.message.type = "warning";
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.linha.id_cad_linhas = this.id;
        this.$http
          .post("/rotas/create", this.linha)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.key = r.data.key;
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              this.loaderRotas();
              this.clearFields();
              console.log(this.loaderRotas());
            } else {
              this.message.text = r.data.error;
              this.message.snacshow = true;
              this.message.type = "warning";
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
    deleteRoute(id) {
      let c = confirm("Deseja apagar o registro?");
      if (c) {
        this.$store.dispatch("activeLoader", true);
        this.$http.post(`/rotas/delete/${id}`).then((r) => {
          this.$store.dispatch("activeLoader", false);
          alert(r.data.success);
          this.loaderRotas();
        });
      }
    },
    editRoute(item) {
      this.linha = item;
      this.showModal = true;
    },
    loaderRotas() {
      this.items = [];
      if (this.id) {
        this.$store.dispatch("activeLoader", true);
        this.$http
          .get(`/rotas/${this.id}`)
          .then((r) => {
            this.items = r.data;
            this.$store.dispatch("activeLoader", false);
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$store.dispatch("activeLoader", true);
        this.$http
          .get(`/rotas/${this.linha.id_cad_linhas}`)
          .then((r) => {
            this.items = r.data;
            this.$store.dispatch("activeLoader", false);
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
